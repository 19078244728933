<template>
  <div class="accords flex flex-col">
    <div class="heading1">
      Educational Accords
      <hr class="gradientLine" />
    </div>

    <div
      class="flex w-full justify-between gap-8 h8:flex-row flex-col-reverse flex-grow"
    >
      <div class="flex flex-wrap h-min gap-8 mb-8 w-full">
        <p v-if="filteredAccords_hack.length === 0">
          No accords found. Please try a different search.
        </p>

        <Accord
          v-else
          v-for="document in filteredAccords_hack"
          :data-type="document.dataType"
          :id="document.repoId"
          :key="document.id"
          class="w-full"
        >
        </Accord>
      </div>
      <div
        class="p-6 h-fit bg-white rounded-lg shadow-xl flex flex-col gap-4 k1:sticky k1:top-32"
      >
        <div class="text-2xl font-bold leading-none">Filter your results</div>
        <TextInput @input="filterText"></TextInput>
        <div class="flex flex-col gap-4 content-between mr-4">
          <Dropdown
            name="country"
            title="Countries"
            :items="countries"
            @select="filterCountry"
          ></Dropdown>
          <Dropdown
            name="principle"
            title="Principle"
            :items="[
              ...Object.values(principles).map((principle) => principle.name),
            ]"
            @select="filterPrinciple"
          ></Dropdown>
          <!--              <PrincipleFilter
                              :dropdownTitle="principleDropdown || 'Filter by principle'"
                              @filter="onSearch"
                            ></PrincipleFilter>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accord from "@/components/accords/Accord.vue";
import { useDocuments } from "@/composables/useDocuments";
import TextInput from "@/components/forms/TextInput.vue";
import { LIST_ASSETS } from "@/store/operations";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import Dropdown from "@/components/forms/Dropdown.vue";
import principles from "@/components/accords/principles.js";
import { countries } from "@/components/Organisation/lib/countries";

export default {
  name: "Accords",
  components: {
    TextInput,
    Accord,
    Dropdown,
  },
  setup() {
    const principleDropdown = ref(null);
    const countryDropDown = ref(null);
    const store = useStore();
    const dataType = "Accord";
    const { filter, filteredDocuments } = useDocuments(dataType, {
      refresh: true,
    });

    const filterCountry = (selectedItems) => {
      console.log(selectedItems);
      countryDropDown.value = selectedItems;
      // Reset the filter value
      delete filter.value.countries;

      for (const item of selectedItems) {
        if (item === "All") {
          delete filter.value.countries;
          break;
        }
        filter.value.countries = item;
      }
    };
    const filterPrinciple = (selectedItems) => {
      selectedItems = Object.values(selectedItems).map((item) =>
        Object.keys(principles).find((key) => principles[key].name === item)
      );
      filter.value.principles = selectedItems;

      console.log(selectedItems);
    };
    const filteredAccords_hack = computed(() => {
      // TODO: remove this hack, removes public kids of private parent
      const filtered = [];
      if (filteredDocuments.value?.length && store.state.assets?.Organisation) {
        for (const accord of filteredDocuments.value) {
          const parent = Object.values(store.state.assets.Organisation).find(
            (o) => o.repoId === accord.parentId
          );
          const parentIsPublic = parent && !parent.isPrivate;
          if (parentIsPublic) filtered.push(accord);
        }
      }
      return filtered.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
    });

    const filterText = (text) => {
      if (text === null) {
        delete filter.value.name;
        return;
      }

      text = text.toLowerCase();

      filter.value = { name: text, description: text };
    };

    store.dispatch(LIST_ASSETS, { dataType });

    const onSearch = (value) => {
      const key = Object.keys(value)[0];
      principleDropdown.value = value;
      if (value[key] !== undefined) filter.value[key] = value[key];
      else delete filter.value[key];
    };

    return {
      countries,
      filteredAccords_hack,
      onSearch,
      principleDropdown,
      countryDropDown,
      filterCountry,
      filterText,
      principles,
      filterPrinciple,
    };
  },
};
</script>

<style scoped></style>
